<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Categorias das Unidades de Atendimento</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="unitCategories.title"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Nome *"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import UnitCategoryService from '../../../services/unit-category.service';

export default {
  name: 'UnitCategoriesForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      unitCategories: {},
      isLoading: false,
    };
  },
  created() {
    if (this.populateWith.id) {
      this.unitCategories = this.populateWith;
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async createCategory(category) {
      try {
        const data = await UnitCategoryService.create(category);
        this.$toast.success('Categoria da Unidade de Atendimento salva com sucesso');
        return data;
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    async updateCategory(category) {
      try {
        const data = await UnitCategoryService.update(category);
        this.$toast.success('Categoria da Unidade de Atendimento salva com sucesso');
        return data;
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    async submit() {
      this.isLoading = true;
      if (!this.$refs.form.validate()) return;

      const category = {
        ...this.unitCategories,
      };
      if (category.id) {
        const editedCategory = await this.updateCategory(category);
        this.$emit('unit-category-edited', editedCategory);
        this.isLoading = false;
      } else {
        const newCategory = await this.createCategory(category);
        this.$emit('unit-category-added', newCategory);
        this.unitCategories.title = '';
        this.$refs.form.resetValidation();
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
